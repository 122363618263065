import React from 'react'

import GalleryPage, { Gallery } from '../../components/common/gallery';

const ProColorBot = () => (
  <GalleryPage
    imageLinks={
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/procolorbot/procolorbot${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
    moreInfo={{
      linkText: 'How It Works',
      content:
        <Gallery
          imageLinks={
            [1, 2, 3, 4, 5, 6].map((number) => (
              `https://s3.amazonaws.com/valentina-site/portfolio_images/procolorbot/how_it_works/j_j${number}.jpg`
            ))
          }
          navigationColor='#c3c6cc'
          autoplay={false}
        />
    }}
  />
)

export default ProColorBot